<template>
  <div class="content-container">
    <div class="center-container">
      <p class="title">心电生理数据总汇</p>
      <div class="action-bar">
        <div class="action-bar-left"></div>
        <div class="action-bar-right">
          <Upload
            ref="upload"
            :data="{member_id: $route.params.member_id}"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :format="['xls','xlsx']"
            :on-format-error="handleFormatError"
            action="/api/xnwy/xdupload">
            <Button type="primary">导入Excel</Button>
          </Upload>
        </div>
      </div>
      <table class="table" v-if="result.table">
        <tr>
          <th>指标</th>
          <th>心搏</th>
          <th>最快心率</th>
          <th>最慢心率</th>
          <th>平均心率</th>
          <th>停搏漏搏</th>
          <th>房性早搏</th>
          <th>室性早搏</th>
        </tr>
        <tr v-for="(row, index) in result.table" :key="index">
          <td v-for="(data, i) in row" :key="row[0] + i">{{data}}</td>
        </tr>
      </table>
      <div v-else style="text-align:center;padding: 20px 0;">请上传心电生理数据</div>
    </div>
    <div class="symbol-box tac">
      <Button type="primary" size="large" @click="$router.back()">返回</Button>
      <Button type="success" size="large" @click="handleSubmit">提交</Button>
    </div>
  </div>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			result: {},
		};
	},
	methods: {
		handleSuccess(res) {
			this.result = res.d;
		},
		handleFormatError(file) {
			this.$Message.warning('文件 ' + file.name + ' 格式不正确，请上传 xls 或 xlsx 格式的文件。');
		},
		handleSubmit() {
			if (!this.result.table) {
				this.$Message.warning('请上传心电生理数据');
				return;
			}
			const params = {
				member_id: this.$route.params.member_id,
				xm: 3,
				sjrq: this.result.sjrq,
				nr: JSON.stringify(this.result.table),
				file_id: this.result.file_id,
			};
			MemberService.submitECGData(params).then(() => {
				this.$Message.success('提交成功');
				this.$router.back();
			});
		},
	},
};
</script>

<style lang="less" scoped>
.content-container {
  background-color: #fff;
  padding: 15px;
  margin-top: 10px;
  .center-container {
    width: 1000px;
    margin: 0 auto;
  }
  .title {
    text-align: center;
    margin: 10px 0;
    font-size: 22px;
  }
  .action-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 52px;
    margin-bottom: 20px;
  }
  .table {
    width: 100%;
    th, td {
      border: 1px solid;
      padding: 5px 10px;
      text-align: center;
    }
  }
  .symbol-box {
    margin-top: 20px;
  }
}
</style>
